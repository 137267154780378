.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 200px; /* Set a fixed height, or adjust as needed */
}

.scenario-selectors {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.loading-container img {
  max-width: 100%; /* Ensures the image is not bigger than its container */
  max-height: 100%; /* Adjust the size, keeping the aspect ratio */
}


.scenario {
  cursor: pointer;
  transition: transform 0.2s;
  display: flex; /* Make this a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 300px; /* Define a width for the scenario div */
  height: 120px; /* Define a height for the scenario div */
  margin: 20px; /* Optional: Adds some space around each scenario */

}

.scenario img {
  max-width: 100%; /* Ensure the image does not exceed the container's width */
  max-height: 100%; /* Ensure the image does not exceed the container's height */
  object-fit: contain; /* Keeps the image's aspect ratio */
}
.scenario:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}
